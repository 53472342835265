// the below are a collection of routes divided into different arrays depending on the role access list

const hrStats = ['/statistics'];

export const profileRoute = ['/profile'];

export const commonRoutes = [
  '/dashboard',
  '/candidates',
  '/timetracking',
  '/timetracking/:projectCode/:taskNumber',
  '/projects',
  '/project/:projectCode/:taskNumber',
  '/project/:projectCode',
  '/reports',
  '/vacations',
  '/users',
  '/user/:id',
  '/billabilityReports',
  '/benchReports',
  '/wiki',
  '/docbook/:id',
  '/docpage/:id',
  '/:action-docpage/:id',
  '/docpage-history/:id',
  '/requests/:type',
  ...profileRoute,
];

// adminRoutes are associated with the following roles [OWNER, ADMIN, RECRUITER, MANAGER, HR]
export const adminRoutes = [...commonRoutes, ...hrStats, '/reminders-all', '/vacancies', '/vacancy/:id'];

// salesAccEmplRoutes are associated with the following roles [SALES, ACCOUNTANT, EMPLOYEE]
export const salesAccEmplRoutes = [...commonRoutes, ...hrStats];

// contractorRoutes are associated with the following roles [CONTRACTOR]
export const contractorRoutes = commonRoutes.slice(
  commonRoutes.indexOf('/timetracking'),
  commonRoutes.indexOf('/vacations/:type'),
);

export const routesOpenedInSeparateTabs = [];

export const ROUTES = {
  HOME: '/',
  DASHBOARD: '/dashboard',

  PROJECTS: '/projects',
  PROJECT: {
    OVERVIEW: '/project/:projectCode/*',
    OVERVIEW_TASK: '/project/:projectCode/*/:taskNumber',
    OVERVIEW_BOARD: 'board',
    OVERVIEW_BOARD_TASK: 'board/:taskNumber',
    OVERVIEW_BACKLOG: 'backlog',
    OVERVIEW_BACKLOG_TASK: 'backlog/:taskNumber',
    OVERVIEW_MILESTONES: 'milestones',
    OVERVIEW_SPRINTS: 'sprints',
    OVERVIEW_VERSIONS: 'versions',
    OVERVIEW_COMMENTS: 'comments',
    OVERVIEW_TASK_STATISTICS: 'task-statistics',
  },

  TIMETRACKING: '/timetracking',
  TIMETRACKING_PROJECT: '/timetracking/:projectCode/:taskNumber',

  USERS: '/users',
  USER: {
    USER_ID: '/user/:id/*',
    PROFILE: '/profile/*',
    DETAILS: 'details',
    COMMENTS: 'comments',
    REVIEWS: 'reviews',
    FIN_INFO: 'financial_information',
    DOCS_AND_LINKS: 'docs_&_links',
    HISTORY: 'history',
    REMINDERS: 'reminders',
  },

  VACATIONS: {
    ROOT: '/vacations/*',
    MY_VACATIONS: 'my-vacations',
    ALL: 'all',
    REQUESTS: 'requests',
    STATISTICS: 'statistics',
  },

  BILLABILITY_REPORTS: '/billabilityReports',
  BENCH_REPORTS: '/benchReports',

  VACANCIES: '/vacancies',
  VACANCY: {
    ROOT: '/vacancy/:id/*',
    DETAILS: 'details',
    CANDIDATES: 'candidates',
    FUNNEL_OF_CANDIDATES: 'funnel-of-candidates',
  },

  CANDIDATES: {
    ROOT: '/candidates/*',
    BOARD: 'board',
    WITHOUT_VACANCY: 'without-vacancy',
  },

  REPORTS: '/reports',
  REPORTS_PROJECT: '/reports/:projectCode/:type',

  WIKI: {
    ROOT: '/wiki/*',
    ALL: 'all',
    ACTIVE: 'active',
    ARCHIVED: 'archived',
  },

  DOCBOOK: '/docbook/:id',
  DOCBOOK_PAGE: '/docpage/:id',
  DOCBOOK_PAGE_CREATE: '/:action-docpage/:id',
  DOCBOOK_PAGE_HISTORY: '/docpage-history/:id',

  REMINDERS_ALL: '/reminders-all',

  REQUESTS: '/requests/:type',

  HR_STATISTICS: '/statistics',
};

export const PUBLIC_ROUTES = {
  HOME: '/',
  RESET_PASSWORD: '/resetpassword',
  RESET_PASSWORD_BY_EMAIL: '/reset-password/:token',
};
