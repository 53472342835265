import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  accordionDetails: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
  },
}));
