import { createAction, Dispatch } from '@reduxjs/toolkit';
import { ApiService } from 'Redux/middleware/api';
import { ITag, ITagColor } from 'types/tags.types';

export const SET_SELECTED_TAGS = createAction<ITag[]>('tags/SET_SELECTED_TAGS');

export const CLEAR_SELECTED_TAGS = createAction('tags/CLEAR_SELECTED_TAGS');
export const CLEAR_AVAILABLE_TASK_TAGS = createAction('tags/CLEAR_AVAILABLE_TASK_TAGS');
export const CLEAR_TASK_TAG_ERROR = createAction('tags/CLEAR_TASK_TAG_ERROR');

export const GET_TASK_TAGS_SUCCESS = createAction<ITag[]>('tags/GET_TASK_TAGS.SUCCESS');
export const GET_TASK_TAGS_FAILURE = createAction<any>('tags/GET_TASK_TAGS.FAILURE');
export const GET_TASK_TAGS_PENDING = createAction('tags/GET_TASK_TAGS.PENDING');

export type GetProjectTaskTagsPayload = {
  projectId: string;
};

export const getProjectTaskTags =
  ({ projectId }: GetProjectTaskTagsPayload) =>
  async (dispatch: Dispatch) => {
    dispatch(GET_TASK_TAGS_PENDING());
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `/task-tags`,
        qsParams: {
          projectId,
        },
      });

      dispatch(GET_TASK_TAGS_SUCCESS(data.data));
    } catch (err: any) {
      dispatch(GET_TASK_TAGS_FAILURE(err.response.data.message));
    }
  };

export const CREATE_TASK_TAG_SUCCESS = createAction<ITag>('tags/CREATE_TASK_TAG.SUCCESS');
export const CREATE_TASK_TAG_FAILURE = createAction<any>('tags/CREATE_TASK_TAG.FAILURE');
export const CREATE_TASK_TAG_PENDING = createAction('tags/CREATE_TASK_TAG.PENDING');

export type CreateTaskTagPayload = {
  projectId: string;
  text: string;
  palette: ITagColor;
};

export const createTaskTag =
  ({ projectId, text, palette }: CreateTaskTagPayload) =>
  async (dispatch: Dispatch) => {
    dispatch(CREATE_TASK_TAG_PENDING());
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: `/task-tags`,
        query: {
          projectId,
          text,
          palette,
        },
      });
      dispatch(CREATE_TASK_TAG_SUCCESS(data.data));
    } catch (err: any) {
      dispatch(CREATE_TASK_TAG_FAILURE(err.response.data.message));
    }
  };

export const UPDATE_TASK_TAG_SUCCESS = createAction<ITag>('tags/UPDATE_TASK_TAG.SUCCESS');
export const UPDATE_TASK_TAG_FAILURE = createAction<any>('tags/UPDATE_TASK_TAG.FAILURE');
export const UPDATE_TASK_TAG_PENDING = createAction('tags/UPDATE_TASK_TAG.PENDING');

export type UpdateTaskTagPayload = {
  taskTagId: string;
  text?: string;
  palette?: ITagColor;
};

export const updateTaskTag =
  ({ taskTagId, text, palette }: UpdateTaskTagPayload) =>
  async (dispatch: Dispatch) => {
    dispatch(UPDATE_TASK_TAG_PENDING());
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `/task-tags/${taskTagId}`,
        query: {
          text,
          palette,
        },
      });
      dispatch(UPDATE_TASK_TAG_SUCCESS(data.data));
    } catch (err: any) {
      dispatch(UPDATE_TASK_TAG_FAILURE(err.response.data.message));
    }
  };

export const DELETE_TASK_TAG_SUCCESS = createAction<ITag>('tags/DELETE_TASK_TAG.SUCCESS');
export const DELETE_TASK_TAG_FAILURE = createAction<any>('tags/DELETE_TASK_TAG.FAILURE');
export const DELETE_TASK_TAG_PENDING = createAction('tags/DELETE_TASK_TAG.PENDING');

export type DeleteTaskTagPayload = {
  taskTagId: string;
};

export const deleteTaskTag =
  ({ taskTagId }: DeleteTaskTagPayload) =>
  async (dispatch: Dispatch) => {
    dispatch(DELETE_TASK_TAG_PENDING());
    try {
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: `/task-tags/${taskTagId}`,
      });
      dispatch(DELETE_TASK_TAG_SUCCESS(data.data));
    } catch (err: any) {
      dispatch(DELETE_TASK_TAG_FAILURE(err.response.data.message));
    }
  };
