import { createReducer } from '@reduxjs/toolkit';
import { ITag } from 'types/tags.types';
import {
  CLEAR_AVAILABLE_TASK_TAGS,
  CLEAR_SELECTED_TAGS,
  CLEAR_TASK_TAG_ERROR,
  CREATE_TASK_TAG_FAILURE,
  CREATE_TASK_TAG_PENDING,
  CREATE_TASK_TAG_SUCCESS,
  DELETE_TASK_TAG_FAILURE,
  DELETE_TASK_TAG_PENDING,
  DELETE_TASK_TAG_SUCCESS,
  GET_TASK_TAGS_FAILURE,
  GET_TASK_TAGS_PENDING,
  GET_TASK_TAGS_SUCCESS,
  SET_SELECTED_TAGS,
  UPDATE_TASK_TAG_FAILURE,
  UPDATE_TASK_TAG_PENDING,
  UPDATE_TASK_TAG_SUCCESS,
} from '../actions/tasks-tags.actions';

export interface TagState {
  selectedTags: ITag[];
  availableTags: ITag[];
  error: string | null;
  loading: boolean;
  initialized: boolean;
}

const initialState: TagState = {
  selectedTags: [],
  availableTags: [],
  error: null,
  loading: false,
  initialized: false,
};

const handlers = {
  [SET_SELECTED_TAGS as any]: (state: TagState, action: { payload: ITag[] }) => {
    state.selectedTags = action.payload;
  },

  [CLEAR_SELECTED_TAGS as any]: (state: TagState) => {
    state.selectedTags = [];
  },
  [CLEAR_AVAILABLE_TASK_TAGS as any]: (state: TagState) => {
    state.availableTags = [];
    state.initialized = false;
  },
  [CLEAR_TASK_TAG_ERROR as any]: (state: TagState) => {
    state.error = null;
  },

  [GET_TASK_TAGS_SUCCESS as any]: (state: TagState, action: { payload: ITag[] }) => {
    state.availableTags = action.payload;
    state.loading = false;
    state.error = null;
    state.initialized = true;
  },

  [GET_TASK_TAGS_FAILURE as any]: (state: TagState, action: { payload: any }) => {
    state.error = action.payload;
    state.loading = false;
  },

  [GET_TASK_TAGS_PENDING as any]: (state: TagState) => {
    state.loading = true;
    state.error = null;
  },

  [CREATE_TASK_TAG_SUCCESS as any]: (state: TagState, action: { payload: ITag }) => {
    state.availableTags.push(action.payload);
    state.selectedTags.push(action.payload);
    state.loading = false;
    state.error = null;
  },

  [CREATE_TASK_TAG_FAILURE as any]: (state: TagState, action: { payload: any }) => {
    state.error = action.payload;
    state.loading = false;
  },

  [CREATE_TASK_TAG_PENDING as any]: (state: TagState) => {
    state.loading = true;
    state.error = null;
  },

  [UPDATE_TASK_TAG_SUCCESS as any]: (state: TagState, action: { payload: ITag }) => {
    state.availableTags = state.availableTags.map((tag) => (tag._id === action.payload._id ? action.payload : tag));
    state.selectedTags = state.selectedTags.map((tag) => (tag._id === action.payload._id ? action.payload : tag));
    state.loading = false;
    state.error = null;
  },

  [UPDATE_TASK_TAG_FAILURE as any]: (state: TagState, action: { payload: any }) => {
    state.error = action.payload;
    state.loading = false;
  },

  [UPDATE_TASK_TAG_PENDING as any]: (state: TagState) => {
    state.loading = true;
    state.error = null;
  },

  [DELETE_TASK_TAG_SUCCESS as any]: (state: TagState, action: { payload: ITag }) => {
    state.availableTags = state.availableTags.filter((tag) => tag._id !== action.payload._id);
    state.selectedTags = state.selectedTags.filter((tag) => tag._id !== action.payload._id);
    state.loading = false;
    state.error = null;
  },

  [DELETE_TASK_TAG_FAILURE as any]: (state: TagState, action: { payload: any }) => {
    state.error = action.payload;
    state.loading = false;
  },

  [DELETE_TASK_TAG_PENDING as any]: (state: TagState) => {
    state.loading = true;
    state.error = null;
  },
};

export default createReducer(initialState, handlers);
