import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  accordionSummary: {
    fontSize: '20px',
    backgroundColor: '#333333',
    paddingRight: 0,
    paddingLeft: 0,
    minHeight: 0,
    transition: 'color 250ms linear',
    '&.Mui-expanded': {
      minHeight: 0,
      margin: 0,
    },
    '&:hover': {
      color: '#a1a1a1',
    },
  },
  accordionSummaryContent: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionSummaryExpandIcon: {
    padding: 0,
  },
}));
