import { createTheme } from '@mui/material/styles';
import { colors } from '../styles/colors';
import { typography } from '../styles/typography';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary.main,
      light: colors.primary.light,
      dark: colors.primary.dark,
      contrastText: colors.primary.contrastText,
    },
    secondary: {
      main: colors.secondary.main,
      light: colors.secondary.light,
      dark: colors.secondary.dark,
      contrastText: colors.secondary.contrastText,
    },
    error: {
      main: colors.error.main,
      light: colors.error.light,
      dark: colors.error.dark,
    },
    warning: {
      main: colors.warning.main,
      light: colors.warning.light,
      dark: colors.warning.dark,
    },
    info: {
      main: colors.info.main,
      light: colors.info.light,
      dark: colors.info.dark,
    },
    success: {
      main: colors.success.main,
      light: colors.success.light,
      dark: colors.success.dark,
    },
    grey: {
      50: colors.grey[50],
      100: colors.grey[100],
      200: colors.grey[200],
      300: colors.grey[300],
      400: colors.grey[400],
      500: colors.grey[500],
      600: colors.grey[600],
      700: colors.grey[700],
      800: colors.grey[800],
      900: colors.grey[900],
    },

    custom: colors.custom,
  },
  typography: {
    mainFont: typography.fontFamily.primary,
  },
});

export default theme;
