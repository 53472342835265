export const colors = {
  primary: {
    main: '#3f51b5',
    light: '#757de8',
    dark: '#002984',
    contrastText: '#fff',
  },

  secondary: {
    main: '#f50057',
    light: '#ff4081',
    dark: '#c51162',
    contrastText: '#fff',
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#d3d4d5',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#6F6F70',
    600: '#424242',
    700: '#333333',
    800: '#212121',
    900: '#151515',
  },

  error: {
    main: '#f44336',
    light: '#e57373',
    dark: '#d32f2f',
  },
  warning: {
    main: '#ff9800',
    light: '#ffb74d',
    dark: '#f57c00',
  },
  info: {
    main: '#2196f3',
    light: '#64b5f6',
    dark: '#1976d2',
  },
  success: {
    main: '#4caf50',
    light: '#81c784',
    dark: '#388e3c',
  },
  text: {
    primary: '#000',
    secondary: '#fff',
    disabled: '#808080',
  },
  custom: {
    white: '#fff',
    black: '#000',
    azure: '#007BFF',
    darkOliveBeige: '#A68958',
    antiFlashWhite: '#F2F3F4',
    deepCarminePink: '#F22A2A',
    babyPink: '#F6C5C5',
    crayola: '#FD4F4F',

    // Додайте інші кольори які вам потрібні
  },
} as const;

export type CustomColors = (typeof colors)['custom'];
