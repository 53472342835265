import { lazy, Suspense, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import initLocalization from './i18n';

import { getCurrentUser, getUserAdditionRole, getUserRole } from './Redux/selectors/auth';

import Sidebar from './components/navigations-section/sidebar';
import PrivateRoute from './components/private-route';
import Loading from './shared/loading';

import { RolePermissions } from './Helpers/RolePermissions';

import { format, getDay } from 'date-fns';
import { fridayTimelogStatus, tokenStorage } from './Helpers/storageFunctions';
import { setFridaySubmitStatus } from './Redux/actions/timeLogs';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { fetchCurrentUser } from './Redux/actions/auth';
import NotificationProvider from './components/notification/notificationContext';
import theme from './theme/theme';

import { useAppDispatch } from 'Redux';
import PublicRoute from 'components/public-route';
import './App.scss';
import { PUBLIC_ROUTES, ROUTES } from './assets/routes';

interface AppProps {
  role: string;
  setFridaySubmit: (status: boolean) => void;
}

initLocalization();

const App = ({ role, setFridaySubmit }: AppProps) => {
  const dispatch = useAppDispatch();

  const userAdditionRole = useSelector(getUserAdditionRole);
  const [startRoute] = RolePermissions.canAccessRoutes(role, userAdditionRole);
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    const { status, date } =
      fridayTimelogStatus().getStatus() ?? fridayTimelogStatus().setStatus(false) ?? fridayTimelogStatus().getStatus();
    if (status && format(new Date(), 'yyyy-MM-dd') !== date && getDay(new Date()) === 5) {
      setFridaySubmit(true);
      fridayTimelogStatus().setStatus(false);
    }

    if (!status && getDay(new Date()) === 5) {
      setFridaySubmit(true);
    }

    if (getDay(new Date()) !== 5) {
      fridayTimelogStatus().setStatus(false);
    }
  }, [setFridaySubmit]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <div className='App'>
            <Suspense fallback={<Loading className='loading' />}>
              <NotificationProvider>
                {currentUser && (
                  <>
                    <Sidebar>
                      <main className='content-container'>
                        <Routes>
                          <Route path={ROUTES.HOME} element={<Navigate to={ROUTES.DASHBOARD} replace />} />
                          <Route
                            path={ROUTES.DASHBOARD}
                            element={
                              <PrivateRoute
                                path={ROUTES.DASHBOARD}
                                component={lazy(() => import('./containers/dashboard'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.PROJECTS}
                            element={
                              <PrivateRoute
                                path={ROUTES.PROJECTS}
                                component={lazy(() => import('./containers/projects'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.PROJECT.OVERVIEW}
                            element={
                              <PrivateRoute
                                path={ROUTES.PROJECT.OVERVIEW}
                                component={lazy(() => import('./containers/project-overview'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.PROJECT.OVERVIEW_TASK}
                            element={
                              <PrivateRoute
                                path={ROUTES.PROJECT.OVERVIEW_TASK}
                                component={lazy(() => import('./containers/project-overview'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.USER.PROFILE}
                            element={
                              <PrivateRoute
                                path={ROUTES.USER.PROFILE}
                                component={lazy(() => import('./containers/user-overview'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.USER.USER_ID}
                            element={
                              <PrivateRoute
                                path={ROUTES.USER.USER_ID}
                                component={lazy(() => import('./containers/user-overview'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.USERS}
                            element={
                              <PrivateRoute path={ROUTES.USERS} component={lazy(() => import('./containers/teams'))} />
                            }
                          />

                          <Route
                            path={ROUTES.BILLABILITY_REPORTS}
                            element={
                              <PrivateRoute
                                path={ROUTES.BILLABILITY_REPORTS}
                                component={lazy(() => import('./containers/billabilityReports'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.BENCH_REPORTS}
                            element={
                              <PrivateRoute
                                path={ROUTES.BENCH_REPORTS}
                                component={lazy(() => import('./containers/bench-statistics'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.VACANCIES}
                            element={
                              <PrivateRoute
                                path={ROUTES.VACANCIES}
                                component={lazy(() => import('./containers/vacancies'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.VACANCY.ROOT}
                            element={
                              <PrivateRoute
                                path={ROUTES.VACANCY.ROOT}
                                component={lazy(() => import('./containers/vacancy-overview'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.CANDIDATES.ROOT}
                            element={
                              <PrivateRoute
                                path={ROUTES.CANDIDATES.ROOT}
                                component={lazy(() => import('./containers/candidates-overview'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.TIMETRACKING}
                            element={
                              <PrivateRoute
                                path={ROUTES.TIMETRACKING}
                                component={lazy(() => import('./containers/timetracking'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.TIMETRACKING_PROJECT}
                            element={
                              <PrivateRoute
                                path={ROUTES.TIMETRACKING_PROJECT}
                                component={lazy(() => import('./containers/timetracking'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.REPORTS}
                            element={
                              <PrivateRoute
                                path={ROUTES.REPORTS}
                                component={lazy(() => import('./containers/reports'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.REPORTS_PROJECT}
                            element={
                              <PrivateRoute
                                path={ROUTES.REPORTS_PROJECT}
                                component={lazy(() => import('./containers/reports'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.WIKI.ROOT}
                            element={
                              <PrivateRoute
                                path={ROUTES.WIKI.ROOT}
                                component={lazy(() => import('./containers/wiki'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.DOCBOOK}
                            element={
                              <PrivateRoute
                                path={ROUTES.DOCBOOK}
                                component={lazy(() => import('./containers/docbookpage'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.DOCBOOK_PAGE}
                            element={
                              <PrivateRoute
                                path={ROUTES.DOCBOOK_PAGE}
                                component={lazy(() => import('./containers/docpage'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.DOCBOOK_PAGE_CREATE}
                            element={
                              <PrivateRoute
                                path={ROUTES.DOCBOOK_PAGE_CREATE}
                                component={lazy(() => import('./containers/docpage-create'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.DOCBOOK_PAGE_HISTORY}
                            element={
                              <PrivateRoute
                                path={ROUTES.DOCBOOK_PAGE_HISTORY}
                                component={lazy(() => import('./containers/docpage-history'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.VACATIONS.ROOT}
                            element={
                              <PrivateRoute
                                path={ROUTES.VACATIONS.ROOT}
                                component={lazy(() => import('./containers/vacations'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.REMINDERS_ALL}
                            element={
                              <PrivateRoute
                                path={ROUTES.REMINDERS_ALL}
                                component={lazy(() => import('./containers/reminders'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.REQUESTS}
                            element={
                              <PrivateRoute
                                path={ROUTES.REQUESTS}
                                component={lazy(() => import('./containers/vacancies'))}
                              />
                            }
                          />

                          <Route
                            path={ROUTES.HR_STATISTICS}
                            element={
                              <PrivateRoute
                                path={ROUTES.HR_STATISTICS}
                                component={lazy(() => import('./containers/hr-statistics'))}
                              />
                            }
                          />

                          {currentUser &&
                            (currentUser.role === 'CANDIDATE' || currentUser.role === 'PARTNER' ? (
                              <Route path={ROUTES.USER.DETAILS} element={<Navigate to={ROUTES.USER.DETAILS} />} />
                            ) : (
                              <Route path={startRoute} element={<Navigate to={startRoute} />} />
                            ))}
                        </Routes>
                      </main>
                    </Sidebar>
                  </>
                )}

                {!currentUser && !tokenStorage().getAccessToken() && (
                  <Routes>
                    <Route
                      path={PUBLIC_ROUTES.HOME}
                      element={<PublicRoute component={lazy(() => import('./containers/login'))} />}
                    />

                    <Route
                      path={PUBLIC_ROUTES.RESET_PASSWORD}
                      element={<PublicRoute component={lazy(() => import('./containers/reset-password'))} />}
                    />

                    <Route
                      path={PUBLIC_ROUTES.RESET_PASSWORD_BY_EMAIL}
                      element={<PublicRoute component={lazy(() => import('./containers/reset-password-by-email'))} />}
                    />
                    <Route path='*' element={<Navigate to={PUBLIC_ROUTES.HOME} />} />
                  </Routes>
                )}
              </NotificationProvider>
            </Suspense>
          </div>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const mapStateToProps = (state: any) => ({
  role: getUserRole(state),
});

const mapDispatchToProps = {
  setFridaySubmit: setFridaySubmitStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
