import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RolePermissions } from '../../Helpers/RolePermissions';
import { getUserAdditionRole, getUserRole } from '../../Redux/selectors/auth';

interface PrivateRouteProps {
  path: string;
  component: React.LazyExoticComponent<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, path }) => {
  const userRole = useSelector((state) => getUserRole(state));
  const userAdditionRole = useSelector(getUserAdditionRole);
  const [startRoute] = RolePermissions.canAccessRoutes(userRole, userAdditionRole);

  const hasAccessToRoute = () => {
    const rolePaths = RolePermissions.canAccessRoutes(userRole, userAdditionRole);

    const baseRoute = path.split('/*')[0];

    return rolePaths.some(
      (allowedPath) => allowedPath === baseRoute || baseRoute.startsWith(allowedPath.replace(':id', '')),
    );
  };

  if (!hasAccessToRoute() || !userRole) {
    return <Navigate to={startRoute} replace />;
  }

  return <Component />;
};

export default PrivateRoute;
