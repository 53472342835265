import { AccordionSummaryProps, AccordionSummary as MuiAccordionSummary } from '@mui/material';
import { useStyles } from './styles';

type AccordionSummaryClasses = {
  root?: string;
  expanded?: string;
  content?: string;
  expandIconWrapper?: string;
};

export const AccordionSummary = (props: AccordionSummaryProps) => {
  const { classes: styleClasses } = useStyles();

  const classes: AccordionSummaryClasses = {
    root: styleClasses.accordionSummary,
    content: styleClasses.accordionSummaryContent,
    expandIconWrapper: styleClasses.accordionSummaryExpandIcon,
  };

  return <MuiAccordionSummary classes={classes} {...props} />;
};
