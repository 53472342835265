import React from 'react';

interface PublicRouteProps {
  component: React.LazyExoticComponent<any>;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ component: Component }) => {
  return <Component />;
};

export default PublicRoute;
