import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  accordionTitle: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '1.3',
    marginLeft: '14px',
  },
  iconContainer: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  accordionTitleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));
