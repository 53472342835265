export const uk = {
  translation: {
    reportSave: 'Зберегти звіт',
    total: 'Загальна кількість',
    team: 'Команда',
    selectUser: 'Оберіть користувача',
    selectProject: 'Оберіть проект',

    yes: 'ТАК',

    searchByName: 'Пошук по імені',

    year_one: 'рік',
    year_two: 'роки',
    year_other: 'років',

    date: 'Дата',
    startDateVacancies: 'Відкриття вакансії',
    endDateVacancies: 'Закриття вакансії',
    toastPasswordText: 'Пароль змінено!',
    toastInfoText: 'Інформацію змінено!',

    typographyActionResetPassword: 'Забули пароль?',
    typographyActionLinkResetPassword: 'Відновити пароль',
    typographyActionPasswordDidntMatch: 'Два поля пароля не збігаються',
    expiredLinkTitle: 'Термін дії вашого посилання закінчився',
    expiredLinkText: 'Будь ласка, спробуйте скинути пароль ще раз',
    profileLabel: 'Профіль',
    profileMyLabel: 'Мій профіль',
    profileUserLabel: 'профіль',
    logoutLabel: 'Вийти',

    userTableFullName: "Повне Iм'я",
    userTableRole: 'Роль',
    userTableWorkingHours: 'Кількість робочих годин',
    userTableBillableHours: 'Оплачувані години',
    userTableNonBillableHours: 'Не оплачувані години',
    userTableVacation: 'Відпустки',
    userProjectName: 'Назва проекту',
    userTableBench: 'Бенч',
    userTableDayOff: 'Day-Off (за власний рахунок)',
    userTableSickDay: 'Лікарняний',
    userTableBenchPercentage: 'Bench відсоток (%)',
    userTableEmail: 'OmiSoft Пошта',
    userTableStatus: 'Статус',
    userTableSalary: 'Зарплата',
    userTableRate: 'Рейт',
    userTableGrade: 'Ступінь',
    userTableSpecialization: 'Спеціалізація',
    userTableEnglishLevel: 'Рівень англійської',
    userTableRoles: 'Ролі',

    candidateHistoryAuthor: 'Автор',
    candidateHistoryOldStatus: 'Старий статус',
    candidateHistoryNewStatus: 'Новий статус',
    candidateHistoryDate: 'Дата запису',

    commentsTableTitle: 'Назва',
    commentsTableComment: 'Коментар',
    commentsTableDate: 'Дата',

    trackingPageTitle: 'Відстеження часу',
    vacationsPageTitle: 'Запити на відсутність',

    timelogTitle: 'Назва проекту / Назва Задачі',
    timelogUsername: 'Користувач',
    timelogComment: 'Коментар',
    timelogDate: 'Дата',
    timelogStatus: 'Таймлог',

    timelogPageTitle: 'Відстеження часу',
    timelogBtnAdd: 'Додати Запис',
    timelogModalAddTitle: 'Додати Запис',
    timelogModalEditTitle: 'Редагувати Запис',
    timelogModalProject: 'Вибрати проект',
    timelogModalTask: 'Задача',
    timelogModalComment: 'Коментар',
    timelogModalDate: 'Дата',
    timelogModalBtnCancel: 'Відмінити',
    timelogModalBtnEdit: 'Оновити Запис',
    timelogModalBtnDelete: 'Видалити Запис',
    timelogModalProjectLabel: 'Проект',
    timelogModalTaskLabel: 'Вибрати задачу',
    timelogModalHourLabel: 'Години',
    timelogModalMinutesLabel: 'Хвилини',
    timelogMO: 'ПН',
    timelogTU: 'ВТ',
    timelogWE: 'СР',
    timelogTH: 'ЧТ',
    timelogFR: 'ПТ',
    timelogSA: 'СБ',
    timelogSU: 'НД',

    projectsBtnAdd: 'Додати проект',
    projectsModalAddTitle: 'Додати проект',
    projectsModalEditTitle: 'Редагувати проект',
    projectsModalBtnCancel: 'Відмінити',
    projectsModalBtnDelete: 'Видалити проект',
    projectsModalBtnSave: 'Зберегти зміни',
    projectsModalName: 'Назва',
    projectsModalCustomer: 'Клієнт',
    projectsModalDate: 'Дата початку',
    projectsModalSwitcher: 'Чи активний проект?',
    projectIsBillableSwitch: 'Чи платний проект?',
    projectsDeleteConfirmTitle: 'Ви впевнені, що хочете видалити цей проект?',
    projectsDeleteConfirmBtnCancel: 'Відмінити',
    projectsDeleteConfirmBtnDelete: 'Видалити',
    projectsTabsBacklog: 'Беклог',
    projectsTabsBoard: 'Дошка',
    projectsTabsMilestones: 'Майлстоуни',
    projectsTabsSprints: 'Спринти',
    projectsTabsVersions: 'Версії',

    meetingsPageTitle: 'Стендап-зустрічі',
    projectsPageTitle: 'Проекти',
    dashboardPageTitle: 'Основна інформація',
    dashboardStatisticsPageTitle: 'Статистика',
    dashboardTrialPeriodTitle: 'Випробувальний термін',
    started: 'Початок',
    nonTrimmedField: 'Поле не повинно містити пробіли',
    nonValidEmail: 'Введіть дійсну електронну пошту',
    passwordsDontMatch: 'Два поля пароля не збігаються',
    requiredField: "Це поле є обов'язковим",
    tooShortFirstName: "Ім'я занадто коротке",
    tooShortLastName: 'Прізвище занадто коротке',
    greaterThanField: 'Число має бути більше 0',

    minValueWorkingHours: 'Мінімальне значення робочих годин 0',
    maxValueWorkingHours: 'Максимальне значення робочих годин 24',

    integerField: 'Число має бути цілим',
    correctCodeField: 'Введіть правильний код',
    zeroTimeError: 'Час не може бути рівним 0!',

    basicInfoTab: 'Основна інформація',
    locationTab: 'Місце розташування',
    contactsTab: 'Контакти',
    financialInfoTab: 'Фінанси',
    securityTab: 'Безпека',
    historyTab: 'Історія',
    requestsTab: 'На розгляді',
    vacationsPerYear: 'Статистика',
    vacationsTab: 'Усі запити',
    myVacationsTab: 'Мої запити',

    createUserTitle: 'Створення користувача',
    createCandidateTitle: 'Створити кандидата',
    createCandidateSuccessMsg: 'Кандидат успішно створено',
    createCandidateErrorMsg: 'Щось пішло не так',
    editUserTitle: 'Редагувати користувача',
    editCandidateTitle: 'Редагувати кандидата',
    basicInfoTitle: 'Основна інформація',
    locationTitle: 'Місце розташування',
    contactInfoTitle: 'Контактна інформація',
    financialInfoTitle: 'Фінансова інформація',
    hrInfoTitle: 'Інформація для HR',
    signInTitle: 'Увійти',
    resetPasswordTitle: 'Скинути пароль',
    resetPasswordDialogTitle: 'На вашу електронну пошту надіслано новий пароль',
    resetPasswordOneTimeLinkTitle: 'Посилання для зміни пароля надіслано на вашу електронну пошту',
    resetPasswordSuccess: 'Пароль успішно змінено',

    docBookMembersTitle: 'Учасники',
    docBookAdminsTitle: 'Редактори',
    createNewDocTitle: 'Створити сторінку',
    editNewDocTitle: 'Редагувати сторінку',

    firstNameLatinField: "Ім'я (латиниця)",
    lastNameLatinField: 'Прізвище (латиниця)',
    firstNameCyrillicField: "Ім'я (кирилиця)",
    lastNameCyrillicField: 'Прізвище (кирилиця)',
    fullNameCyrillicField: "Повне Ім'я (кирилиця)",
    aboutField: 'Про мене',
    birthdayField: 'День народження',
    emailField: 'Пошта',
    omiEmailField: 'Пошта OmiSoft',
    mobilePhoneField: 'Телефон',
    telegramField: 'Телеграм',
    passwordField: 'Пароль',
    oldPasswordField: 'Старий пароль',
    newPasswordField: 'Новий пароль',
    confirmPasswordField: 'Підтвердіть пароль ще раз',
    reasonForDismissal: 'Причина звільнення',
    projectNameField: 'Назва проекту',
    projectCustomerField: "Ім'я клієнта",
    projectIsActiveField: 'Показати деактивовані проекти',
    weekField: 'Тиждень',
    monthField: 'Місяць',
    yearField: 'Рік',
    anotherField: 'Довільний проміжок',
    shortNameField: "Коротке ім'я",
    usersIsActiveField: 'Показати деактивованих юзерів',
    specializationField: 'Спеціалізація',
    developerGradeField: 'Ступінь',
    techStackField: 'Технологічний стек',
    englishLevelField: 'Рівень англійської',
    englishField: 'Англійська',
    roleField: 'Роль',
    candidateStatusField: 'Статус кандидата',
    openVacanciesField: 'Відкриті вакансії',
    chosenVacancy: 'Вакансія',
    workingHoursField: 'Робочі години',
    trelloCardLinkField: 'Посилання Trello',
    cvLinkField: 'Посилання на резюме',
    otherLinksField: 'Інші посилання',
    countryField: 'Країна',
    cityField: 'Місто',
    novaPoshtaAddressField: 'Адреса Нової Пошти',
    skypeField: 'Skype',
    gitHubField: 'GitHub користувача',
    gitLabField: 'GitLab користувача',
    linkedInField: 'LinkedIn',
    upworkLinkField: 'UpWork посилання',
    fopField: 'Ви ФОП? (Приватний підприємець)',
    payoneerEmailField: 'Електронна пошта Payoneer',
    monobankCardField: 'Картка Монобанк',
    privatBankCardField: 'Картка ПриватБанк',
    ipnField: 'ІПН (Індивідуальний податковий номер)',
    dateOfStartingField: 'Дата початку',
    dateOfBirthday: 'День народження',
    channelField: 'Канал (djinni, dou ...)',
    minSalaryField: 'Мінімальна зарплата',
    maxSalaryField: 'Максимальна зарплата',
    minRateField: 'Мінімальний тариф',
    maxRateField: 'Максимальний тариф',
    hiringCommissionField: 'Комісія найму',
    commissionReceiverField: 'Отримувач комісії',
    holidayNameLatin: 'Назва (латиниця)',
    holidayNameCyrillic: 'Назва (кирилиця)',
    holidayDate: 'Дата свята',
    newDocPageTitle: 'Заголовок',
    newDocPageTags: 'Теги',
    titleField: 'Заголовок',
    contentField: 'Опис',
    bookStatusField: 'Видимість книги',
    historySourceField: 'Джерело',
    historyTargetField: 'Таргет',

    columnName: 'Назва колонки',
    columnStatus: 'Статус',
    otherColumn: 'Інша колонка',
    addColumn: 'Додати колонку',
    columnNameAlreadyExists: 'Та куди ти, ця колонка вже є',
    manageProjectColumnButton: 'Редагувати Колонки',
    editColumnsModalTitle: 'Редагувати колонки',
    confirmDeleteColumnTitle: 'Ви впевнені що хочете видалити колонку?',
    columnAddSuccessMsg: 'Колонку успішно додано',
    columnUpdateSuccessMsg: 'Колонку успішно відредаговано',
    columnDeleteSuccessMsg: 'Колонку успішно видалено',
    chooseColumnForDeletedColumnTasksTitle: 'Оберіть колонку для переміщення завдань',

    okButton: 'OK',
    saveButton: 'Зберегти',
    editButton: 'Редагувати',
    editProjectColumnsButton: 'Редагувати колонки',
    editProjectButton: 'Редагувати проект',
    editProfileButton: 'Редагувати профіль',
    avatarSelectMenu: 'Ви можете вибрати аватар',
    avatarSelectButton: 'Завантажити аватар',
    sendButton: 'Надіслати',
    deleteButton: 'Видалити',
    cancelButton: 'Відмінити',
    cancelChangesButton: 'Скасувати зміни',
    filtersButton: 'Фільтри',
    membersButton: 'Учасники',
    adminsButton: 'Редактори',
    confirmButton: 'Підтвердити',
    resetButton: 'Відновити',
    generatePasswordButton: 'Сгенерувати новий пароль',
    createUserButton: 'Створити користувача',
    createCandidateButton: 'Створити кандидата',
    updateUserButton: 'Оновити користувача',
    updateCandidateButton: 'Оновити',
    closeUserInfoButton: 'Закрити інформацію',
    goToButton: 'Перейти',
    addHolidayButton: 'Додати свято',
    deleteHolidayButton: 'Видалити свято',
    saveChangesButton: 'Зберегти зміни',
    savePasswordButton: 'Зберегти пароль',
    submitBtn: 'Далі',
    approveButton: 'Підтвердити',
    declineButton: 'Відхилити',
    createDocPageBtn: 'Створити сторінку',
    activateButton: 'Активувати',
    deactivateButton: 'Деактивувати',
    addToSlack: 'Додати до Slack',
    connectedToSlack: 'Приєднано до Slack',
    activatedMsg: 'Активний',
    deactivatedMsg: 'Деактивовано',
    activeMsg: 'Активний',
    createDocBook: 'Створити книгу',
    submitFridayStatusTitle: 'Ти затрекав тиждень?',
    submitFridayStatus: 'Так, я затрекав увесь тиждень',
    denyFridayStatus: 'Ні, пізніше',
    editDocBook: 'Редагувати книгу',
    deleteBook: 'Видалити книгу',
    archivePage: 'Заархівувати сторінку',
    unarchivePage: 'Розархівувати сторінку',
    searchUsers: 'Пошук користувачів',
    searchTitle: 'Пошук',
    searchProject: 'Пошук проекта',
    globalSearch: 'Глобальний пошук',
    teamSearch: 'Пошук у команді',
    assignToLabel: 'Назначити на',
    assignedToLabel: 'Назначено на',
    notAssigned: 'Не назначено',
    priorityLabel: 'Пріоритет',
    goTocomments: 'Перейти до коментарів',
    isFired: 'Звільнений',
    candidatesAttribute: 'Додати мітку',
    hrInterview: "HR інтерв'ю",
    checkedEnglishLevel: 'Перевірені знання англ. мови',
    needPing: ' Повторний пінг',
    testTaskIsDone: 'Виконане тестове завдання',
    partTime: 'Неповний робочий день',
    fullTime: 'Повний робочий день',
    raisingStar: 'Перспективний кандидат',

    createVacationButton: 'Створити запит',
    selectReviewers: "Оберіть рев'юверів",
    requestVacation: 'Додати запит',
    addVacation: 'Створити',
    editVacationRequest: 'Редагувати запит на відпустку',
    vacationRequestAdd: 'Запит на відпустку доданий',
    vacationRequestsFailure: 'Помилка при додаванні запиту на відпустку',
    vacationConfirmationMainMessage: 'Ви впевнені, що хочете створити запит на {{type}} на {{dayNumber}} {{dayText}}? ',
    vacationRequestChange: 'Запит на відпустку змінений',
    vacationReviewersChange: "Список рев'юверів змінений",
    vacationDeleteMessage: 'Запит на відпустку видалено',
    vacationStatusChange: 'Статус запита на відпустку змінено',
    createVacationSuccess: 'Вакансію успішно створено',
    vacationTypeSick: 'Лікарняний',
    vacationTypesDayOff: 'Вихідний',
    unpaid: 'Не оплачується',
    vacationTypesDayOffForConfirm: 'Неоплачуваний вихідний',
    vacationTypesVacation: 'Відпустка',
    vacationTypesVacationForConfirm: 'Відпустку',
    reviewers: "Рев'ювери",
    pendingStatus: 'Очікується',
    approvedStatus: 'Схвалено',
    declinedStatus: 'Відхилено',
    changeReviewers: "Змінити рев'юверів",
    deleteVacationTitle: 'Ви впевнені, ща хочете видалити запит на відпустку?',
    changeVacationStatusApprove: 'Підтвердити запит на відпустку?',
    changeVacationStatusDecline: 'Відхилити запит на відпустку?',
    vacationToReview: 'Будь ласка, розглянте відпустку',
    allVacations: 'Всі відпустки',
    futureVacations: 'Майбутні запити',

    labelYes: 'Так',
    labelNo: 'Ні',
    labelFire: 'Звільнити',
    labelCancel: 'Відмінити',
    labelStartDate: 'Дата початку',
    labelEndDate: 'Дата кінця',
    labelUserName: 'Користувач',
    labelYear: 'Виберіть рік',
    labelMonth: 'Виберіть місяць',
    labelPeriod: 'Період',
    labelComment: 'Коментар',
    labelType: 'Тип',
    statusLabel: 'Статус',
    totalDays: 'Загальна кількість днів',
    requestsLabel: 'Запити',

    reportDeleteConfirmTitle: 'Ви впевнені, що хочете видалити цей репорт?',
    reportDeleteConfirmBtnCancel: 'Відмінити',
    reportDeleteConfirmBtnDelete: 'Видалити',

    dashboardSection: 'Основна інформація',
    meetingsSection: 'Стендап-зустрічі',
    projectsSection: 'Проекти',
    teamSection: 'Команда',
    billabilityReportsSection: 'Billability звіти',
    benchReportsSection: 'Bench звіти',
    candidatesSection: 'Кандидати',
    timeTrackingSection: 'Відстеження часу',
    RecruitmentHRSection: 'Рекрутинг та HR',
    reportsSection: 'Звіти',
    timeTrackingReportSection: 'Звіт відстеження часу',
    wikiSection: 'Вікі',
    vacationsSection: 'Запити на відсутність',
    remindersSection: 'Нагадування',
    vacanciesSection: 'Вакансії',
    hrStatisticsSection: 'HR cтатистика',

    projectNameCell: 'Назва проекту',
    customerNameCell: "Ім'я клієнта",
    startDateCell: 'Дата початку',
    billableCell: 'Оплачується',
    billable: 'Оплачується',
    notBillable: 'Не оплачується',
    projectTasksTitleCell: 'Назва',
    projectTasksDescriptionCell: 'Опис',
    projectTasksBudgetCell: 'Бюджет',
    projectTasksStatusCell: 'Статус',
    projectTasksTrackedTime: 'Затреканий час',
    projectTaskStatus: 'Статус',
    projectTaskPriority: 'Пріоритет',
    projectTaskExecutor: 'Виконавець',
    taskHistoryEventCommentExecutorTitle: 'виконавця',
    projectTaskExecutorDelete: 'Неназначений',
    projectTaskAuthor: 'Автор',
    projectTasksBillableCell: 'Платне завдання',
    projectTasksDeleteComment: 'Ви впевнені що хочете видалити коментар?',
    projectErrorDeleteTask: 'Ви не можете видалити цю задачу, оскільки вона має затрекані години',
    commenHasBeenEdited: 'Коментар відредаговано',
    commentUpdate: 'Оновлено',
    commentDeleted: 'Коментар видалено',
    newCommentCreated: 'Новий коментар створено',
    editComment: 'Редагувати коментар...',
    addAComment: 'Додати коментар...',
    projectTaskStatusBacklog: 'Беклог',
    projectTaskStatusTodo: 'To-do',
    projectTaskStatusInProgress: 'В процесі',
    projectTaskStatusTesting: 'Тестування',
    projectTaskStatusDone: 'Готово',
    projectTaskStatusFailed: 'Відхилено',
    projectTaskStatusAll: 'Усі',
    projectTaskStatusCurrent: 'Діючі',
    projectSprintStatusNew: 'Новий',
    projectSprintStatusCurrent: 'Поточний',
    projectSprintStatusPast: 'Минулий',
    allSprints: 'Всі (без Минулих)',
    projectVersionStatusReleased: 'В релізі',
    projectVersionStatusFuture: 'Майбутня',
    projectVersionStatusInDevelop: 'В розробці',
    projectVersionStatusArchive: 'Архівована',
    allVersions: 'Всі (без Архівних)',

    newChecklistCreated: 'Новий перелік створено',
    ChecklistDeleted: 'Перелік видалено',
    checklist: 'Перелік',
    newCheckItemCreated: 'Новий пункт переліку створено',
    newCheckItemDeleted: 'Пункт переліку видалено',

    taskSpecialTypeTitle: 'Спец. тип',
    taskSpecialTypeDelete: 'Видалити спец. тип',
    taskSpecialTypeSickDay: 'Лікарняний',
    taskSpecialTypeDayOff: 'Day-Off',
    taskSpecialTypeVacation: 'Відпустка',
    taskSpecialTypePreSale: 'Pre-Sale',
    taskSpecialTypeBench: 'Бенч',
    taskSpecialTypeInterviews: `Інтерв'ю`,

    taskPriorityLow: 'Низький',
    taskPriorityMedium: 'Середній',
    taskPriorityHigh: 'Високий',
    taskPriorityCritical: 'Критичний',
    deleteTaskPriority: 'Видалити пріоритет',

    newBacklogButton: 'Додати в беклог',

    newProjectTaskTitle: 'Додати нову задачу',
    editProjectTaskTitle: 'Редагувати задачу',
    addProjectTaskButton: 'Додати задачу',
    editProjectTaskButton: 'Редагувати задачу',
    addProjectMilestoneButton: 'Додати майлстоун',
    editProjectMilestoneButton: 'Редагувати майлстоун',
    sprintTitle: 'Спринт',
    addProjectSprintButton: 'Додати спринт',
    editProjectSprintButton: 'Редагувати спринт',
    changeProjectSprintStatus: 'Змінити статус спринта',
    editSprintConfirmationText:
      'Ви справді хочете зробити цей спринт поточним? Зверніть увагу, що статус існуючого поточного спринта буде автоматично змінено на “минулий“.',
    versionTitle: 'Версія',
    taskHistoryEventCommentVersionTitle: 'версію',
    addProjectVersionButton: 'Додати версію ',
    editProjectVersionButton: 'Редагувати версію',
    changeProjectVersionStatus: 'Змінити статус версії',
    statusFilter: 'Cтатус',
    nameFilter: 'Назва',

    projectTaskTitle: 'Назва',
    projectTaskDescription: 'Опис',
    projectAddTaskDescription: 'Додати опис',
    projectTaskBudget: 'Бюджет (години)',
    projectTaskBillable: 'Платне завдання',
    projectTaskTitleOrDescription: 'Назва або опис',
    projectTaskActivity: 'Активність',
    projectActivityComments: 'Комментарі',
    projectsActivityHistory: 'Історія',
    projectsActivityWorklogs: 'Журнал Роботи',
    createTaskText: 'створив(ла) це завдання',
    chooseValueText: 'обрав(ла)',
    removeValueText: 'прибрав(ла)',
    changeValueText: 'змінив(ла)',
    forTask: 'для цього завдання',
    fromTask: 'з цього завдання',
    ofTaskFrom: 'для цього завдання з',
    to: 'на',
    at: 'о',
    setTaskToBillable: 'зробив це завдання платним',
    setTaskToUnbillable: 'зробив це завдання безоплатним',
    showButtons: 'Показати : ',
    loadMore: 'Підгрузити Ще',
    noHistoryEvents: 'Це завдання не має історії подій',

    projectCodeTitle: 'Код проекту',
    maxNumberOfSymbols: '10 символів максимум',
    upperCaseOnly: 'Код повинен містити лише великі літери та цифри',
    withoutSpaceOnly: 'Код не має містити пробіли',

    projectMilestoneName: 'Назва',
    projectMilestoneTaskName: 'Задача',
    projectMilestoneDescription: 'Опис',
    projectMilestoneStartDate: 'Початок',
    projectMilestoneEndDate: 'Кінець',
    period: 'Період',
    projectMilestoneStatus: 'Статус',
    projectSprintNumber: 'Номер',
    projectSprintTaskName: 'Задача',
    projectSprintDescription: 'Опис',
    projectSprintStartDate: 'Початок',
    projectSprintEndDate: 'Кінець',
    projectSprintStatus: 'Статус',
    projectVersionName: 'Назва',
    projectVersionTaskName: 'Задача',
    projectVersionDescription: 'Опис',
    projectVersionStartDate: 'Початок',
    projectVersionReleaseDate: 'Дата релізу',
    projectVersionStatus: 'Статус',
    projectVacanciesStatus: 'Статус',

    deleteMilestoneModalTitle: 'Ви впевнені, що хочете видалити цей майлстоун?',
    addToMilestoneButton: 'Додати до майлстоуну',
    changeMilestoneButton: 'Змінити майлстоун',
    deleteFromMilestoneBtn: 'Видалити з майлстоуну',
    noMilestones: 'Відсутні майлстоуни',
    addToMilestoneTitle: 'Виберіть майлстоун',
    milestoneTitle: 'Майлстоун',
    withoutMilestone: 'Без майлстоуну',
    projectSprintChangeStatusBtn: 'Змінити статус спринта',
    deleteFromSprintBtn: 'Видалити із спринта',
    projectVersionChangeStatusBtn: 'Змінити статус версії',
    deleteFromVersionBtn: 'Видалити з версії',

    callRecapProjectComment: 'Обговорення дзвінка',
    requirementsProjectComment: 'Вимоги',
    designProjectComment: 'Дизайн',
    estimationProjectComment: 'Оцінка',
    taskManagementProjectComment: 'Управління завданнями',
    otherProjectComment: 'Інше',

    projectMembersTitle: 'Учасники проекту',
    projectAdminsTitle: 'Адміни проекту',

    timeLogConfirmDelete: 'Видалити запис',
    timeLogConfirmDeleteTitle: 'Ви впевнені, що хочете видалити цей запис?',
    timeLogEmptyProjects: 'У вас немає активних проектів',

    statsBoxWorkers: 'Працівники',
    statsBoxContractors: 'Контрактори',
    statsBoxProjects: 'Мої проекти',
    statsBoxCandidates: 'Кандидати',

    dashboardTasksTitle: 'Задачі',
    dashboardHolidaysTitle: 'Державні свята',
    dashboardChartTitle: 'Робочий час команди',
    dashboardBirthdaysTitle: 'Дні народження',
    dashboardTasksReport: 'Відправте щоденний репорт',
    dashboardTasksTimeLog: 'Відстежте час на сьогодні',
    dashboardTasksVacations: 'Запити на відсутність',
    dashboardChartMyTitle: 'Мій робочий час',
    dashboardVacationsTitle: 'Відпустки та вихідні дні цього тижня',
    dashboardAnniversaryTitle: 'Річниця в компанії',
    billableHoursLabel: 'Оплачувані години',
    nonBillableHoursLabel: 'Неоплачувані години',

    addHolidayTitle: 'Додати свято',
    editHolidayTitle: 'Редагувати свято',
    holidayConfirmDelete: 'Ви впевнені, що хочете видалити це свято?',

    filterByRole: 'За роллю',
    filterByStatus: 'Статус кандидата',
    filterByName: "Повне Ім'я",
    filterBySpecialization: 'Спеціалізація',
    userActiveStatus: 'Статус користувача',
    filterByGrade: 'За рангом',
    filterByAddRole: 'За додатковою роллю',
    filterByVacancy: 'Вакансія',
    filterByVacancyStatus: 'Статус вакансії',
    filterByCandidateAttribute: 'Атрибут',
    filterByRefusalReason: 'Причина відмови',
    allStatusesFilter: 'Усі',
    filterByUser: 'Користувач',
    filterAllCandidates: 'Усі кандидати',
    startDateFilter: 'Дата початку',
    endDateFilter: 'Кінцева дата',
    isBillableFilter: 'Платні завдання',
    milestoneFilter: 'Майлстоуни',

    removeProjectFilter: 'Видалити фільтр проекту',
    removeUserFilter: 'Видалити фільтр користувача',
    removeFilter: 'Видалити фільтр',
    removeVacancyCandidate: 'Видалити вакансію',
    removeTaskSpecialTypeFilter: 'Видалити спец. тип',

    noDataWarning: 'Немає даних',
    noProjectOrUserSelected: 'Виберіть проект або користувача',
    noVacancySelected: 'Виберіть вакансію або спеціалізацію',
    noCandidates: 'Кандидатів не знайдено',

    allRoles: 'Усі ролі',
    ownerRole: 'Власник',
    accountantRole: 'Бухгалтер',
    adminRole: 'Адміністратор',
    managerRole: 'Менеджер',
    recruiterRole: 'Рекрутер',
    leadRole: 'Lead',
    HRRole: 'HR',
    salesRole: 'Продажі',
    employeeRole: 'Співробітник',
    contractorRole: 'Підрядник',
    partnerRole: 'Партнер',
    candidateRole: 'Кандидат',
    roleArray: 'Співробітник, Менеджер, Lead',

    allStatuses: 'Усі статуси',
    newStatus: 'Нова заявка',
    preScreeningStatus: 'Попередній скринінг',
    onPendingStatus: 'На розгляді лідів',
    hrInterviewStatus: `HR інтерв'ю`,
    checkingEnglishLevelStatus: 'Перевірка рівня англійської',
    suitableStatus: 'Підходить',
    approvedByLeadStatus: 'Затверджено лідом',
    testStatus: 'Тестове завдання',
    techInterviewStatus: `Технічне інтерв'ю`,
    preSuitableStatus: 'Попередньо підходить',
    customerInterviewStatus: `Інтерв'ю з клієнтом`,
    notSuitableStatus: 'Не підходить',
    sentOfferStatus: 'Надіслали Offer',
    acceptedOfferStatus: 'Offer прийнято',
    onboardingStatus: 'Онбоардінг',
    inTeamStatus: 'В команді',
    blacklistStatus: 'Чорний список',
    needPingStatus: 'Повторний пінг',
    contractorStatus: 'Контрактер',
    doneStatus: 'Готово',

    hrInterviewAttribute: 'HR Співбесіда',
    englishLevelAttribute: 'Перевірка англійської мови',
    needPingAttribute: 'Повторний пінг',
    testTaskAttribute: 'Тестове завдання виконане',
    partTimeAttribute: 'Неповна зайнятість',
    fullTimeAttribute: 'Повний робочий день',
    starAttribute: 'Перспективний',

    meetingAddStatus: 'Додати статус',
    meetingEditStatus: 'Редагувати статус',
    meetingDate: 'Дата зустрічі',

    meetingCellName: "Ім'я користувача",
    meetingCellYesterday: 'Що ви зробили вчора?',
    meetingCellToday: 'Що ви будете робити сьогодні?',
    meetingCellTodayDetail: 'Що ви будете робити сьогодні? (3 основні завдання, пріоритет зверху вниз)',
    meetingCellBlockers: 'Чи були блокери на вашому шляху?',

    projectCreateSuccessMsg: 'Проект успішно створений',
    projectEditSuccessMsg: 'Проект успішно оновлений',
    projectDeleteSuccessMsg: 'Проект успішно видалено',
    projectMembersSuccessMsg: 'Учасники проекту успішно оновлені',
    projectAdminsSuccessMsg: 'Адміни проекту успішно оновлені',
    timelogCreateSuccessMsg: 'Таймлог успішно створений',
    timelogUpdateSuccessMsg: 'Таймлог успішно оновлений',
    timelogDeleteSuccessMsg: 'Таймлог успішно видалений',
    timelogCreateFailureMsg: 'Помилка створення таймлога',
    timelogUpdateFailureMsg: 'Помилка оновлення таймлога',
    timelogDeleteFailureMsg: 'Помилка видалення таймлога',
    warningTimeTrackingMessage:
      'Увага! Будь ласка, трекайте години в той день, за який відпрацьовуєте. Наприклад, у вівторок ви працювали 5 годин і допрацьовували у суботу 3 години - ці 3 год слід логувати у вівторок. Зверніть увагу, що у вихідні можна логувати тільки ',
    overtimeTitle: 'овертайми.',
    timeTrackingInfoMessage: '',
    overtimeInfoMessage: 'Овертайм - це час понад робочі години (у робочі дні), а також робота у вихідні і святкові',
    noTasksAvailableMsg: 'Немає доступних завдань',
    trackTimePenalty: 'Будь ласка, залогуйте свій час на сьогодні',
    dailyReportPenalty: 'Будь ласка, відправте щоденний статус',
    weekTimeTraked: 'Будь ласка, підтвердіть робочі години на цьому тижні',
    docsNotFound: 'Документацій не знайдено',
    pageNotFound: '404 Сторінка не знайдена',
    notFoundText: 'Сторінка, яку ви шукаєте, не існує',
    candidatesNotFound: 'Кандидатів не знайдено',
    docBookMembersUpdatedMsg: 'Учасники документації успішно оновлені',
    docBookAdminsUpdatedMsg: 'Адміни документації успішно оновлені',
    docPageCreateSuccessMsg: 'Сторінка успішно створена',
    docPageEditSuccessMsg: 'Сторінка успішно оновлена',
    docPageArchiveSuccessMsg: 'Сторінка успішно заархівована',
    docPageUnarchivedSuccessMsg: 'Сторінка успішно розархівована',
    docBookCreateSuccessMsg: 'Книгу успішно створено',
    docBookEditSuccessMsg: 'Книгу успішно оновлено',
    docBookChangeStatusSuccessMsg: 'Статус книги успішно змінено',
    holidayCreateSuccessMsg: 'Свято успішно створено',
    holidayEditSuccessMsg: 'Свято успішно оновлено',
    holidayDeleteSuccessMsg: 'Свято успішно видалено',
    AddMeetingReportSuccessMsg: 'Щоденний статус успішно створено',
    EditMeetingReportSuccessMsg: 'Щоденний статус успішно оновлений',
    DeleteMeetingReportSuccessMsg: 'Щоденний статус успішно видалений',
    projectTaskAddSuccessMsg: 'Завдання проекту успішно створено',
    projectTaskEditSuccessMsg: 'Завдання проекту успішно оновлено',
    projectTaskDeleteSuccessMsg: 'Завдання проекту успішно видалено',
    projectTasktrackTimeErrorMsg: 'Ви не є учасником проекту, тому не можете логувати години',
    projectTasktrackTimeAnotherUserErrorMsg:
      'Ви не є редактором проекту, тому не можете логувати години для інших учасників проекту',
    projectMilestoneAddSuccessMsg: 'Майлстоун проекту успішно створено',
    projectMilestoneEditSuccessMsg: 'Майлстоун проекту успішно оновлено',
    projectMilestoneDeleteSuccessMsg: 'Майлстоун проекту успішно видалено',
    projectSprintAddSuccessMsg: 'Спринт проекту успішно створено',
    projectSprintEditSuccessMsg: 'Спринт проекту успішно оновлено',
    projectSprintChangeStatusSuccess: 'Статус спринта успішно змінено',
    projectVersionAddSuccessMsg: 'Версія проекту успішно створена',
    projectVersionEditSuccessMsg: 'Версія проекту успішно оновлена',
    projectVersionChangeStatusSuccess: 'Статус версії успішно змінено',

    createUserSuccessMsg: 'Користувача успішно створено',
    editUserSuccessMsg: 'Користувача успішно оновлено',
    changeUserStatusSuccessMsg: 'Статус користувача успішно оновлено',
    changeUserStatusErrorMsg: 'Не вдалося оновити статус користувача',
    changeUserEmailErrorMsg: 'Користувач із цією електронною адресою вже існує',
    docBookDeleteWarningMsg: 'Ви впевнені, що хочете видалити цю книгу?',
    docPageArchiveWarningMsg: 'Ви впевнені, що хочете заархівувати цю сторінку?',
    docPageUnarchiveWarningMsg: 'Ви впевнені, що хочете розархівувати цю сторінку?',
    cantSaveReportMsg: 'Немає даних. Неможливо зберегти звіт',
    errorInvalidDate: 'Кінцева дата має бути пізніше за дату початку',
    errorExceedingDateInterval: 'Інтервал дати не може перевищувати 3 місяці',
    specializationCreateSuccessMsg: 'Спеціалізація успішно створена',
    specializationUpdateSuccessMsg: 'Спеціалізація успішно оновлена',
    specializationDeleteSuccessMsg: 'Спеціалізація успішно видалена',
    techStackCreateSuccessMsg: 'Технологічний стек успішно створено',
    techStackUpdateSuccessMsg: 'Технологічний стек успішно оновлено',
    techStackDeleteSuccessMsg: 'Технологічний стек успішно видалено',
    addTaskForProject: 'Задача успішно створена',
    deletedTaskForProject: 'Задача успішно видалена',

    selectTaskRadio: 'Вибрати задачу',
    createTaskRadio: 'Створити задачу',

    createTaskTitle: 'Назва нової задачі',
    userTitle: 'Користувач',

    trackTimeMenu: 'Залогувати Час',
    addTag: 'Додати тег',
    addList: 'Створити перелік',
    confirmDeleteList: 'Ви впевнені, що хочете видалити цей перелік?',
    add: 'Додати',
    addItem: 'Додати пункт',
    trackTimeForUserMenu: 'Залогувати час для користувача',
    deleteTaskMenu: 'Видалити',
    showPageHistoryMenu: 'Історія сторінки',
    archiveMenu: 'Архівувати',
    unarchiveMenu: 'Розархівувати',

    deleteModalTitle: 'Ви впевнені, що хочете видалити цю задачу?',
    deleteModalSubmit: 'Видалити',
    deleteModalDeny: 'Скасувати',
    dontSaveTaskModal: 'Ні',
    saveTaskModal: 'Так',
    saveTaskChangesTitle: 'Зберегти зміни?',

    JanMonth: 'Січ',
    FebMonth: 'Лют',
    MarMonth: 'Берез',
    AprMonth: 'Квіт',
    MayMonth: 'Трав',
    JunMonth: 'Черв',
    JulMonth: 'Лип',
    AugMonth: 'Серп',
    SepMonth: 'Верес',
    OctMonth: 'Жовт',
    NovMonth: 'Листоп',
    DecMonth: 'Груд',

    deactivatedUser: 'Деактивований',

    totalTimeCell: 'Загальний час',
    totalOvertimeCell: 'включаючи понаднормовий час',
    goBackBtn: 'Повернутися назад',
    goBackBtnReset: 'Повернутися до',
    userDetailsTab: 'Деталі',
    userCommentsTab: 'Коментарі',
    userFinancialInfoTab: 'Фінансова інформація',
    userDocsAndLinksTab: 'Документи & Лінки',
    createCommentButton: 'Створити коментар',
    editCommentButton: 'Редагувати коментар',

    candidateReviewUserComment: 'Відгук про кандидата',
    englishTestUserComment: 'Перевірка англійської',
    testTaskUserComment: 'Тестове завдання',
    interviewReviewUserComment: 'Відгук про співбесіду',
    refusalReasonUserComment: 'Причина відмови',
    employeeDismissal: 'Звільнення працівника',
    otherUserComment: 'Інше',

    noExperienceRefusalReason: 'Немає комерційного досвіду',
    lowEnglishRefusalReason: 'Низький рівень володіння англійською',
    requiresHighSalaryRefusalReason: 'Потребує завелику ЗП',
    noContactRefusalReason: "Перестав виходити на зв'язок",
    workInOfficeRefusalReason: 'Бажає працювати в офісі',
    interviewFailedRefusalReason: 'Не пройшов співбесіду',
    offerDeclinedRefusalReason: 'Відмовився від оффера або прийняв інший',
    toxicRefusalReason: 'Токсична людина чи неадекватна',
    overratedOwnCapabilitiesReason: 'Переоцінює свої можливості',

    userCommentEditSuccess: 'Коментар успішно оновлено',
    userCommentCreateSuccess: 'Коментар додано',
    commentTitleLabel: 'Заголовок коментаря',
    refusalReasonLabel: 'Причина відмови',
    commentDescriptionLabel: 'Опис коментаря',
    anonymouslyStatusLabel: 'Анонімно',
    userCommentDeleteTitle: 'Ви впевнені, що хочете видалити коментар?',
    userCommentDeleteSuccess: 'Коментар успішно видалено',
    vacationComment: 'Коментар',

    userReviewsTab: 'Відгуки',
    anonymousAuthor: 'Анонімний автор',
    suggestedToStartDoingTitle: 'Що колезі варто почати робити',
    suggestedToStopDoingTitle: 'Що колезі варто перестати робити',
    suggestedToContinueDoingTitle: 'Що колега робить добре і має продовжувати',
    userReviewDeleteTitle: 'Ви впевнені, що хочете видалити відгук?',
    createReviewButton: 'Створити відгук',
    editReviewButton: 'Редагувати відгук',
    deleteReviewButton: 'Видалити відгук',
    reviewCreateSuccess: 'Відгук створено',
    reviewEditSuccess: 'Відгук редаговано',
    reviewDeleteSuccess: 'Відгук видалено',

    userRemindersTab: 'Нагадування',
    createReminderLabel: 'Створити нагадування',
    editReminderLabel: 'Редагувати нагадування',
    reminderAuthor: 'Автор',
    reminderUser: 'Користувач',
    reminderDate: 'Дата',
    reminderDescription: 'Опис',
    createReminderSuccess: 'Нагадування створено',
    editReminderSuccess: 'Нагадування змінено',
    deleteReminderSuccess: 'Нагадування успішно видалено',
    deleteReminderTitle: 'Ви впевнені, що хочете видалити нагадування?',
    remindersPenalty: 'Перевірте сьогоднішні нагадування!',
    goToReminders: 'Перейти до нагадувань кандидата',
    noReminders: 'Немає нагадувань',

    docBookPrivateStatus: 'Приватна',
    docBookPublicStatus: 'Публічна',
    allDocbooksTitle: 'Усі книги',
    activeDocbooksTitle: 'Активні книги',
    archivedDocbooksTitle: 'Архівовані книги',
    docBookActivate: 'Активувати',
    docBookArchivate: 'Архівувати',
    docPageAllStatus: 'Усі сторінки',
    docPageActiveStatus: 'Активні',
    docPageArchivedStatus: 'Заархівовані',

    candidateChennel: 'Джерела кандидатів',
    vacancyStatus: 'Статус вакансії',
    vacancyTitle: 'Вакансія',
    vacancySpecialization: 'Спеціалізація',
    additionRole: 'Додаткові ролі',
    vacancyGrade: 'Ступінь',
    vacancyDescription: 'Про вакансію',
    vacancyStartDate: 'Дата початку',
    vacancyEndDate: 'Дата завершення',
    vacancyOpen: 'Відкрита',
    vacancyEdit: 'Редагування',
    vacancyTemplate: 'Шаблон',
    vacancyCancelled: 'Скасована',
    vacancyCloseSuccessful: 'Закрита успішно',
    vacancyCloseUnSuccessful: 'Закрита неуспішно',
    vacancyArchive: 'Архів',
    createVacancy: 'Створити вакансію',
    createVacancyFromTemplate: 'Створити вакансію з шаблону',
    editVacancy: 'Редагувати вакансію',
    editStatusVacancy: 'Змінити статус вакансії',
    openTheVacancy: 'Ви точно хочете відкрити вакансію?',
    closeTheVacancy: 'Ви точно хочете закрити вакансію?',
    confirmOpenVacancy: 'Відкрити',
    confirmCloseVacancy: 'Закрити',
    vacancyEditSuccess: 'Вакансія була успішно змінена!',
    vacancyStatusSuccess: 'Статус вакансії був успішно змінений!',
    backToVacancies: 'Повернутись до вакансій',
    allStatusesVacancies: 'Всі статуси',
    deleteVacancy: 'Видалити вакансію',
    deleteVacancyConfirm: 'Ви точно хочете видалити цю вакансію?',
    deleteVacancySuccess: 'Вакансія була успішно видалена!',
    chooseTemplate: 'Виберіть шаблон',
    vacancyDetailsTab: 'Деталі',
    vacancyCandidatesTab: 'Кандидати',
    candidatesTab: 'Дошка',
    noVacancyCandidatesTab: 'Список',
    noVacancyCandidates: 'Кандидати без вакансії',
    vacancyFunnelOfCandidatesTab: 'Статистика',
    vacancyCandidatesNoData: 'По цій вакансії ще немає жодного кандидата.',
    noCandidateHistory: 'По цьому кандидату ще немає жодної історії',
    goToVacancyButton: 'Перейдіть до вакансії',

    createChannelTitle: 'Додати канал',
    createChannelButton: 'Додати',
    editChannelTitle: 'Редагувати канал',
    channelSelectTitle: 'Виберіть канал',
    editChannelButton: 'Редагувати',
    deleteChannelTitle: 'Видалити канал',
    deleteChannelButton: 'Видалити',
    deleteChannelConfirm: 'Виберіть спеціалізацію який ви хочете видалити.',
    channelName: 'Назва',
    newChannelName: 'Нова назва',

    createSpecializationTitle: 'Додати спеціалізацію',
    createSpecializationButton: 'Додати',
    editSpecializationTitle: 'Редагувати спеціалізацію',
    specializationSelectTitle: 'Виберіть спеціалізацію',
    editSpecializationButton: 'Редагувати',
    deleteSpecializationTitle: 'Видалити спеціалізацію',
    deleteSpecializationButton: 'Видалити',
    deleteSpecializationConfirm: 'Виберіть спеціалізацію яку ви хочете видалити.',
    specializationName: 'Назва',
    newSpecializationName: 'Нова назва',

    createTechStackTitle: 'Додати технологічний стек',
    createTechStackButton: 'Додати',
    editTechStackTitle: 'Редагувати технологічний стек',
    techStackSelectTitle: 'Оберіть технологічний стек',
    editTechStackButton: 'Редагувати',
    deleteTechStackTitle: 'Видалити технологічний стек',
    deleteTechStackButton: 'Видалити',
    deleteTechStackConfirm: 'Оберіть технологічний стек, який ви хочете видалити.',
    techStackName: 'Назва',
    newTechStackName: 'Нова назва',

    statisticsVacanciesTitle: 'Статистика закриття вакансій',
    labelStartMonth: 'Початковий місяць',
    labelEndMonth: 'Кінцевий місяць',
    closedSuccessfullyField: 'Закриті успішно',
    closedUnSuccessfullyField: 'Закриті не успішно',
    hiringAndFiringStatistics: 'Статистика звільнень та наймів',
    hiredEmpoleesLabel: 'Найняті працівники',
    firedEmpoleesLabel: 'Звільнені працівники',
    dateOfHiring: 'Дата найму',
    dateOfDismissal: 'Дата звільнення',
    statisticsFunnelOfCandidatesTitle: 'Воронка кандидатів',
    vacancyStatusLabel: 'Статус вакансії',
    vacancyLabel: 'Виберіть вакансію',
    suitableStatusCandidate: 'Підходить',
    checkingEnglishStatus: 'Перевірка англійської',
    underLeadsConsideration: 'На розгляді лідом',

    hireCandidate: 'Найняти кандидата',
    canditateEditStatus: 'Редагувати статус',
    fireAnEmployee: 'Звільнити працівника',
    roleTitle: 'Виберіть роль',
    fireEmployee: 'Ви точно хочете звільнити працівника?',
    firedEmployeeSuccessMsg: 'Працівника було звільнено',
    hiredCandidateSuccessMsg: 'Кандидат був прийнятий на роботу',
    deleteEmployeeConfirmation: 'Ви впевнені, що хочете видалити кандидата зі статистики?',
    deleteEmployeeSuccess: 'Кандидат успішно видаленний зі статистики!',

    changeCandidateStatusSuccessMsg: 'Статус кандидата успішно оновлено',
    changeCandidateStatusErrorMsg: 'Не вдалося оновити статус кандидата',
    usersDoesNotExist: 'Користувачів не знайдено',

    documentNameCell: 'Назва документа',
    documentTypeCell: 'Тип документа',
    documentUploadedCell: 'Завантажено',
    documentCreateSuccessMsg: 'Документ успішно збережений ',
    documentUpdateSuccessMsg: 'Документ успішно оновлений ',
    documentUpdateFailureMsg: 'Помилка збереження документа',
    documentCreateFailureMsg: 'Помилка оновлення документа',
    deleteDocumentConfirm: 'Ви точно хочете видалити цей документ?',
    deleteDocumentSuccess: 'Документ успішно видалено',
    deleteDocumentFailure: 'Помилка видалення документа',
    documentMenuFileLabel: 'Додати файл',
    documentMenuLinkLabel: 'Додати посилання',
    documentModalName: 'Назва',
    documentModalDescription: 'Опис',
    documentModalType: 'Тип',
    documentModalVisibility: 'Видимість',
    documentModalLink: 'Посилання',
    documentModalAdd: 'Додати документ',
    documentModalEdit: 'Редагувати документ',
    documentModalAddFile: 'Додати файл',
    documentModalAddLink: 'Додати посилання',
    documentModalEditFile: 'Редагувати файл',
    documentModalEditLink: 'Редагувати посилання',
    documentsDoesntExist: 'Немає доступних документів',
    documentDescriptionCell: 'Опис',
    editUserVacancyInfo: "Не можна змінювати вакансію кандидату зі статусом 'не підходить' чи 'готово'",
    withoutSprint: 'Без спринта',
    withoutVersion: 'Без версії',
    downloadingCandidates: 'Завантаження кандидатів',
    passwordValidationError:
      'Пароль має містити: велику літеру, маленьку літеру, число та спеціальний знак. Мінімальна кількість символів - 8',
    templateTaskLabel: 'Створити як шаблон',
    templateCard: 'Шаблон',
    templateBtnCreate: 'Створити  шаблон',
    templateBtnEdit: 'Редагувати шаблон',
    doNotHaveAccess: 'Ви не маєте доступу до цього проекту',
    projectNotFound: 'Проєкт за даним кодом не знайдено',

    statistics: 'Статистика',

    daysField: 'Дні',
    hoursField: 'Години',
    minutesField: 'Хвилини',

    Week: 'Тиждень',
    Month: 'Місяць',
    Year: 'Рік',

    checkedColumns: 'Вибрані стовпці',
    startDate: 'Дата початку',
    endDate: 'Дата завершення',

    projectStatistics: 'Статистика проекту',

    totalAverage: 'Загальне середнє',
    'Strong Junior': 'Потужний Junior',
    Trainee: 'Trainee',
    Junior: 'Junior',
    Middle: 'Middle',
    'Strong Middle': 'Потужний Middle',
    Senior: 'Senior',

    createTaskFromTemplate: 'Створити задачу з шаблону',
    ThisIsTemplateTask: 'Це шаблон задачі',
    ThisIsTemplateTaskCreate: 'Ця задача буде створена як шаблон',

    settings: 'Налаштування',
    tagSize: 'Розмір тега',
    tags: 'Теги',
    addingNewTag: 'Додавання нового тега',
    currentTag: 'Поточний тег',
    searchTags: 'Пошук тегів',
    selectColor: 'Виберіть колір',
    tagName: 'Назва тега',
    saveTag: 'Зберегти',
    addNewTag: 'Додати новий тег',
    tagNameRequired: "Назва тега є обов'язковою",
    deleteTag: 'Видалити тег',
    exampleTag: 'Приклад',

    confirmationOfDeletion: 'Підтвердження видалення',
    confirmationOfDeletionDescription:
      'Ви впевнені, що хочете видалити цей тег? Якщо ви видалите цей тег, він буде видалений з усіх задач.',
    taskAgingAlerts: 'Позначити застарілі завдання',
    smartCommits: 'Смарт-коміти',
  },
};
